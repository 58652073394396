import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}>{`Gatsby is a React-based, GraphQL powered, static site generator. What does that even mean?  Well, it weaves together the best parts of React, webpack, react-router, GraphQL, and other front-end tools in to one very enjoyable developer experience. Don’t get hung up on the moniker ‘static site generator’.  That term has been around for a while, but Gatsby is far more like a modern front-end framework than a static site generator of old.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It uses powerful preconfiguration to build a website that uses only static files for incredibly fast page loads, service workers, code splitting, server-side rendering, intelligent image loading, asset optimization, and data prefetching. All out of the box. I didn’t believe the speed until I tried it myself.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Where to go from here?`}</MDXTag>{` `}</MDXTag>
      <MDXTag name="p" components={components}>{`Read more on our `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://blog.appseed.us/tag/gatsbyjs/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`blog`}</MDXTag>{` or `}<MDXTag name="strong" components={components} parentName="p"><MDXTag name="a" components={components} parentName="strong" props={{
            "href": "https://appseed.us/fullstack-apps-generator",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`Generate a Full-Stack App`}</MDXTag></MDXTag>{` in Vuejs & React in a few minutes.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Happy coding!`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "title": "About",
  "slug": "about",
  "cover": "./mountains.jpg"
};
      